<template>
  <router-link
    class="link-base"
    :class="centered ? 'centered' : null"
    :to="link"
  >
    <p
      class="text"
      :class="{
        main: type == 1 || type == undefined,
        secondary: type == 2,
        thirdary: type == 3
      }"
    >
      {{ text }}
    </p>
  </router-link>
</template>

<script>
export default {
  name: "Link-base",
  props: {
    centered: Boolean,
    text: String,
    link: String,
    type: Number
  }
};
</script>

<style lang="scss" scoped>
.link-base {
  display: inline-block;
  padding: 15px;

  &.centered {
    display: block;
    width: 100%;

    .text {
      width: 100%;
    }
  }

  .text {
    color: $black;
    font-size: 1.6rem;
    transition: 250ms;
    cursor: pointer;

    &:hover {
      color: $main-color;
    }

    &.main {
      color: $main-color;
      background-color: $transparent !important;
    }
    &.secondary {
      color: $second-color;
      background-color: $transparent !important;
    }
    &.thirdary {
      color: $third-color;
      background-color: $transparent !important;
    }
  }
}
</style>
