var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"base-button",class:{
    block: _vm.isFullWidth,
  },attrs:{"disabled":_vm.disabled,"title":_vm.hoverInfo ? _vm.hoverInfo : null}},[_c('div',{staticClass:"button",class:{
      main: _vm.type === 1,
      secondary: _vm.type === 2,
      thirdary: _vm.type === 3,
      rounded: !_vm.text,
      block: _vm.isFullWidth,
      bigger: _vm.bigger,
      small: _vm.small,
    },on:{"click":_vm.handleClick}},[(_vm.icon)?_c('span',{staticClass:"icon-container"},[_c('Svg-icon',{staticClass:"icon",attrs:{"icon":_vm.icon}})],1):_vm._e(),(_vm.text && !_vm.disabled)?_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")]):(_vm.localDisableText && _vm.disabled)?_c('span',[_vm._v(" "+_vm._s(_vm.localDisableText)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }