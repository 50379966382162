<template>
  <div v-if="isReady" class="card-panel">
    <img class="logo" src="@/assets/img/logo.png" alt="logo" />

    <h3 v-if="title" class="title">{{ title }}</h3>

    <div class="wrapper-messages">
      <slot name="messages"></slot>
    </div>

    <div class="wrapper-input">
      <slot name="inputs"></slot>
    </div>

    <div class="wrapper-links">
      <slot name="links"></slot>
    </div>
  </div>
  <div v-else class="card-panel center">
    <div class="wrapper-loader">
      <Loader width="100" height="100" />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/dom/Loader";

export default {
  components: {
    Loader
  },
  props: {
    title: String,
    isReady: Boolean
  }
};
</script>

<style lang="scss" scoped>
.card-panel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px 0px $grey-main;
  border-radius: 20px;
  max-height: 70vh;
  padding: 60px 150px;
  background-color: $white;

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 175px;
    margin-bottom: 60px;
  }

  .wrapper-messages {
    margin-top: 10px;
  }

  .wrapper-input {
    margin-top: 10px;
  }

  .wrapper-links {
    .link {
      margin: 25px 0 0 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
}
</style>
