<template>
  <button
    class="base-button"
    :class="{
      block: isFullWidth,
    }"
    :disabled="disabled"
    :title="hoverInfo ? hoverInfo : null"
  >
    <div
      class="button"
      :class="{
        main: type === 1,
        secondary: type === 2,
        thirdary: type === 3,
        rounded: !text,
        block: isFullWidth,
        bigger: bigger,
        small: small,
      }"
      @click="handleClick"
    >
      <span v-if="icon" class="icon-container">
        <Svg-icon class="icon" :icon="icon" />
      </span>
      <span v-if="text && !disabled"> {{ text }} </span>
      <span v-else-if="localDisableText && disabled">
        {{ localDisableText }}
      </span>
    </div>
  </button>
</template>

<script>
/*
  type:
   1 => primary
   2 => secondary
   3 => thirdary
*/
import SvgIcon from "@/components/dom/SvgIcon.vue";

export default {
  name: "Button-base",
  components: {
    SvgIcon,
  },
  props: {
    icon: String,
    text: String,
    onClick: Function,
    type: Number,
    hoverInfo: String,
    isFullWidth: Boolean,
    bigger: Boolean,
    disabled: Boolean,
    disabledText: String,
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDisableText: String,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translationsStore.currentLang;
    },
  },
  methods: {
    handleClick(e) {
      if (!this.disabled) {
        this.onClick(e);
      }
    },
  },
  created() {
    this.localDisableText = this.disabledText
      ? this.disabledText
      : this.translations.labels.loading;
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  display: inline-block;

  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 1.4rem;
    transition: 200ms;

    &.bigger {
      padding: 15px 25px;
      font-size: 1.9rem;
    }

    &.small {
      padding: 5px 15px;
      font-size: 1.3rem;
    }

    &.rounded {
      background-color: $main-color;
      color: $white;
      border: 2px solid $main-color;
      padding: 15px;
      border-radius: 50% !important;

      &:hover {
        background-color: $transparent !important;
        color: $main-color !important;
      }
      .icon-container {
        .icon {
          margin: 0;
        }
      }
    }

    &.main {
      background-color: $main-color;
      color: $white;
      border: 2px solid $main-color;

      &:hover {
        background-color: $transparent;
        color: $main-color;
      }
    }
    &.secondary {
      background-color: $second-color !important;
      color: $white !important;
      border: 2px solid $second-color !important;

      &:hover {
        background-color: $transparent !important;
        color: $second-color !important;
      }
    }
    &.thirdary {
      background-color: $third-color !important;
      color: $main-color !important;
      border: 2px solid $third-color !important;

      &:hover {
        background-color: $transparent !important;
        color: $third-color !important;
      }
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 18px;
        margin-right: 10px;
      }
    }

    // &:hover {
    //   background-color: $white;
    //   color: $main-color;
    // }
  }
}

.block {
  display: block !important;
  width: 100%;
}
</style>
