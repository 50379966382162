<template>
  <div class="reset-password-page">
    <CardPanel :isReady="isReady">
      <template v-slot:messages>
        <TextBase>{{ translations.labels.resetPassword_infoText }}</TextBase>
        <TextBase v-if="showErrorToken" type="error">{{
          translations.errors.invalidToken
        }}</TextBase>
        <TextBase v-if="showError" type="error">{{
          translations.errors.somethingWrong
        }}</TextBase>
        <TextBase v-if="showErrorPassword" type="error">{{
          translations.errors.passwordsNotEquals
        }}</TextBase>
      </template>

      <template v-slot:inputs>
        <input
          v-model="user.password"
          class="input-text password"
          type="password"
          :placeholder="translations.labels.password"
          id="password"
        />
        <input
          v-model="user.confirmPassword"
          class="input-text confirmPassword"
          type="password"
          :placeholder="translations.labels.password_confirm"
          id="confirmPassword"
        />
      </template>

      <template v-slot:links>
        <Button-base
          :isFullWidth="true"
          :text="translations.labels.button_continue"
          :onClick="resetPassword"
          :type="1"
          center="center"
          :bigger="true"
        />

        <LinkBase
          :centered="true"
          :text="translations.labels.login"
          link="/login"
        />
      </template>
    </CardPanel>
  </div>
</template>

<script>
import ButtonBase from "@/components/dom/ButtonBase";
import CardPanel from "@/components/panel/CardPanel";
import LinkBase from "@/components/dom/LinkBase";
import Loader from "@/components/dom/Loader";
import Component from "vue-class-component";
import TextBase from "@/components/dom/TextBase";

@Component({
  components: {
    ButtonBase,
    CardPanel,
    LinkBase,
    Loader,
    TextBase,
  },
  inject: {
    authService: "authService",
  },
})
export default class ResetPassword {
  isReady = false;
  showError = false;
  showErrorPassword = false;
  showErrorToken = false;
  user = {
    token: "",
    password: "",
    confirmPassword: "",
  };
  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  async resetPassword() {
    if (this.user.password != this.user.confirmPassword)
      this.showErrorPassword = true;
    else {
      this.showErrorPassword = false;
      this.isReady = false;
      try {
        await this.authService.resetPassword(this.user);
      } catch (err) {
        this.showError = true;
        this.$router.push("/login");
      }
      this.isReady = true;
    }
  }
  async mounted() {
    let requestToken = this.$route.query.token;
    try {
      await this.authService.validateResetToken({
        token: requestToken,
      });
      this.user.token = requestToken;
    } catch (err) {
      this.showErrorToken = true;
    }
    this.isReady = true;
  }
}
</script>
<style lang="scss" scoped>
.reset-password-page {
  background-color: $grey-light;
  width: 100%;
  height: 100%;

  .wrp-reset-panel {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 0);
    background-color: $white;
    box-shadow: 0px 0px 10px 0px $black_07;
    border-radius: 5px;
    width: 370px;
    height: 370px;
    padding: 25px;

    .logo {
      width: 30px;
    }

    .title {
      text-align: center;
    }

    .info {
      background-color: $blue-light;
      color: $white;
      border-radius: 5px;
      font-size: 1.4rem;
      line-height: 20px;
      margin: 0 0 30px 0;
      padding: 5px;

      p {
        margin: 0;
      }
    }

    .email {
      background-color: transparent;
      background-image: none;
      color: $grey-medium;
      border-bottom: 1px solid $grey-lighter;
      border-radius: 0;
      box-shadow: none !important;
      outline: none !important;
      width: 100%;
      height: 45px;
      font-size: 1.6rem;
      line-height: 1.42;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin-bottom: 25px;
    }

    .wrp-button {
      margin: 25px 0 0 0;

      .button {
        background-color: $blue-main;
        color: $white;
        border: 2px solid $blue-main;
        border-radius: 3px;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        padding: 10px 20px;
        font-size: 1rem;
        line-height: 1.5;
        transition: all 0.15s ease-in-out;
        transition: 250ms;
        cursor: pointer;

        &:hover {
          background-color: $white;
          color: $blue-main;
        }

        &.login {
          position: absolute;
          bottom: 30px;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 35px;
        }
      }
    }

    .link {
      font-size: 1.4rem;
      font-weight: 600;
      margin: 25px 0 0 0;
    }

    a {
      text-decoration: none;
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .invalid {
    background-color: $red-lighter !important;
  }
}
</style>
